var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('button',_vm._g({staticClass:"button-ui focus:outline-none",class:[
    _vm.type,
    {
      disabled: _vm.disabled,
      loading: _vm.loading,
      round: _vm.round,
      icon: _vm.icon,
      small: _vm.small,
      dark: _vm.dark,
      plain: _vm.plain,
      large: _vm.large,
      block: _vm.block,
    } ],style:({ width: _vm.width }),attrs:{"disabled":_vm.disabled}},_vm.$listeners),[(_vm.loading)?_c('fontawesome-icon',{attrs:{"icon":"spinner","pulse":"","spin":""}}):_c('span',{staticClass:"button-ui__text"},[_vm._t("default")],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }